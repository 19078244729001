class Portfolio {

  constructor(item, parentEl) {
    this.item = item;
    this.parentEl = parentEl;
  }

  transformUrl(url) {
    const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    const regex = new RegExp(expression);

    if (url.match(regex)) {
      const httpExp = /^https?:\/\//;
      const httpRegex = new RegExp(httpExp);

      if (!url.match(httpRegex)) {
        url = `http://${url}`;
      }
    }

    return url;
  }

  getData() {
    return new Promise((resolve, reject) => {
      if (this.item.data.type === 'block-portfolio-1' && !this.item.data.style) {
        const imageSizes = this.item.data.items.map(this.getSizeImage);

        Promise.all(imageSizes).then(res => {
          resolve(this.getArrVertical(res));
        }).catch();
      } else {
        resolve([ this.item.data.items ]);
      }
    });
  }

  renderGrid(type, style, items) {
    return `<div class="line-portfolio-grid-container">
        <div class="block-portfolio-grid ${type} style-${style || ''}">
          ${ items.map(col => {
            return `<div class="col">${ col.map(row => {
              return `<div class="item">
                        <a ${ row.link.url && 'href="' + this.transformUrl(row.link.url) + '"' || '' }
                           ${ row.link.targetBlank && 'target="_blank"' || '' }
                          alt="Перейти" class="item__image">
                          <img src="${ row.image.big_1920 }" width="100%" height="auto" alt="${row.image.alt||''}">
                        </a>

                        <div class="item__text">
                          <div class="item__text-title">${ row.text.title }</div>
                          <div class="item__text-subtitle">${ row.text.text || '' }</div>
                          <a class="item__text-button" ${ row.link.targetBlank && 'target="_blank"' || '' }
                            ${ row.link.url && 'href="' + this.transformUrl(row.link.url) + '"' || '' }
                            alt="Перейти"></a>
                        </div>

                      </div>`;
                }).join(' ') }</div>`;
          }).join(' ') }
        </div>

    </div>`
  }

 /**
  * @param {{
  *   id: string;
  * }} img
  * @return { Promise<{ ratioToW: number, ratioToH: number }> }
  */
  getSizeImage(data) {
   return new Promise((res, rej) => {
    if (!data.image.width || !data.image.height) {
      const image = new Image();

      image.onload = function() {
        data.image = {
          ...data.image,
          ratioToW: image.naturalWidth / image.naturalHeight,
          ratioToH: image.naturalHeight / image.naturalWidth
        };

        res(data);
      }

      image.src = data.image.big_1920;

    } else {

      data.image = {
        ...data.image,
        ratioToW: data.image.width / data.image.height,
        ratioToH: data.image.height / data.image.width
      };

      res(data);
    }
  })
 }

 /**
  *Promise<{ ratioToW: number, ratioToH: number }>
  * @param {{
  *   id: string;
  * }[]} sizes
  * @return { Array<any[]> }
  */
  getArrVertical(arrExtendedData) {
    const rowCount = this.getWidth() > 768 && 4 || 1;
    const widthEachCol = this.parentEl.getBoundingClientRect().width / rowCount - 30;
    const array = new Array(rowCount).fill(0);
    const arrayCols = new Array(rowCount).fill([]);

    arrExtendedData.forEach((extData) => {
      const minColValue = Math.min(...array);
      const currentCol = array.findIndex(val => val === minColValue);

      array[currentCol] += widthEachCol * extData.image.ratioToH + 30;
      arrayCols[currentCol] = [...arrayCols[currentCol], extData];
    });

    return [...arrayCols];
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

}
